
import {
  defineComponent,
  computed,
  watch,
  reactive,
  toRefs,
  PropType,
} from "vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";
import { FileInfo } from "@/models/Template";

export default defineComponent({
  name: "FilePreview",
  components: {
    PdfViewer,
    Dropdown,
    TextButton,
  },
  props: {
    files: { type: Array as PropType<Array<FileInfo>>, default: () => [] },
    selectedIdx: { type: Number, default: 0 },
    isScale: { type: Boolean, default: false },
    canRemove: { type: Boolean, default: true },
    isPadding: { type: Boolean, default: true },
  },
  emits: ["update:files", "update:selectedIdx"],
  setup(props, { emit }) {
    const state = reactive({
      page: 1,
      numPages: 1,
      pageReady: false,
      fileList: props.files as FileInfo[],
    });

    const { isMobile } = useBreakpoint();

    const isPdf = computed(() => {
      return state.fileList[props.selectedIdx]?.type.indexOf("pdf") >= 0;
    });

    watch(
      () => props.files,
      (val) => {
        state.fileList = val;
      }
    );

    const pageLoaded = (page: number) => {
      state.page = page;
      state.pageReady = true;
    };

    const increment = () => {
      setPage(state.page + 1);
    };

    const decrement = () => {
      setPage(state.page - 1);
    };

    const setPage = (p: any) => {
      if (!p) return;

      const input = document.querySelector(
        "#preview-page-input"
      ) as HTMLInputElement;
      if (p <= 0) {
        if (input) input.value = "1";
        state.page = 1;
      } else if (p >= state.numPages) {
        if (input) input.value = state.numPages.toString();
        state.page = state.numPages;
      } else {
        state.page = parseInt(p);
        state.pageReady = false;
      }
    };

    const removeFile = (index: number) => {
      state.fileList.splice(index, 1);
      selectItemAt(0);
      emit("update:files", state.fileList);
    };

    const selectFile = (id: string) => {
      const index = state.fileList.findIndex((item) => item.id === id);
      selectItemAt(index ?? 0);
    };

    const selectItemAt = (index: number) => {
      emit("update:selectedIdx", index);
    };

    return {
      ...toRefs(state),
      isPdf,
      isMobile,
      pageLoaded,
      increment,
      decrement,
      setPage,
      removeFile,
      selectFile,
      selectItemAt,
    };
  },
});
