
import { FileInfo } from "@/models/Template";
import { defineComponent, reactive, toRefs, PropType } from "vue";
import FileUpload from "@/components/atomics/pdf-viewer/FileUpload.vue";
import FilePreview from "@/components/atomics/pdf-viewer/FilePreview.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "TemplateFileAndInfo",
  components: {
    FileUpload,
    FilePreview,
    ValidationTextField,
  },
  props: {
    files: {
      type: Array as PropType<Array<FileInfo>>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      maxFiles: 5,
      selectedIndex: 0,
    });

    const localFiles = useVModel(props, "files", emit);

    const localTitle = useVModel(props, "title", emit);

    return {
      localFiles,
      localTitle,
      ...toRefs(state),
    };
  },
});
