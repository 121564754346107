
import { ref, defineComponent, reactive, toRefs, watch, PropType } from "vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { FileInfo } from "@/models/Template";

export default defineComponent({
  name: "FileUpload",
  components: {
    TextButton,
    FlatButton,
  },
  props: {
    files: { type: Array as PropType<Array<FileInfo>>, default: () => [] },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:files", "selectedAt"],
  setup(props) {
    const state = reactive({
      input: ref<HTMLInputElement | null>(null),
      maxFiles: 5,
      selectedIdx: 0,
      fileList: props.files as FileInfo[],
    });

    watch(
      () => props.files,
      (val) => {
        state.fileList = val;
      }
    );

    return {
      ...toRefs(state),
    };
  },

  methods: {
    onClickUpload() {
      if (!this.input) return;
      this.input.click();
    },
    resetImageUploader() {
      if (!this.input) return;
      this.input.value = "";
    },
    setFiles(e: any) {
      if (this.fileList.length >= 5) return;

      const uploadFiles = e.target.files || e.dataTransfer.files;
      const remainSlot = this.maxFiles - this.fileList.length;
      const numFilesUpload =
        uploadFiles.length < remainSlot ? uploadFiles.length : remainSlot;

      for (let i = 0; i < numFilesUpload; i++) {
        const fileArr = {
          id: uploadFiles[i].name + i,
          name: uploadFiles[i].name,
          type: uploadFiles[i].type,
          size: uploadFiles[i].size,
          data: uploadFiles[i],
          path: URL.createObjectURL(uploadFiles[i]),
          pageNums: 1,
          currentPage: 1,
          inputs: [],
          scale: 1,
          width: 0,
          height: 0,
        };

        this.fileList.push(fileArr);
      }

      this.$emit("update:files", this.fileList);
      this.$emit("selectedAt", this.selectedIdx);
    },

    removeFile(index: number) {
      if (index == this.selectedIdx) {
        this.selectedIdx = 0;
        this.$emit("selectedAt", 0);
      }
      this.fileList.splice(index, 1);
      this.$emit("update:files", this.fileList);
    },

    selectItemAt(index: number, event: any) {
      if (!event.target.className.includes("icon-del")) {
        this.selectedIdx = index;
        this.$emit("selectedAt", this.selectedIdx);
      }
    },
  },
});
