import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1dc28d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile mt-3 mb-3 ml-2 mr-2" }
const _hoisted_2 = {
  key: 0,
  class: "thin-divider"
}
const _hoisted_3 = {
  key: 1,
  class: "list-area"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["file-upload", { 'has-files': _ctx.fileList.length > 0 }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FlatButton, {
        class: "add-file-btn",
        text: _ctx.$t('common.upload'),
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.readonly ? _ctx.onClickUpload() : null))
      }, null, 8, ["text"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["decoration", 
        _ctx.fileList.length > 0
          ? 'has-files mt-2 ml-2 mb-2 mr-2'
          : 'mt-5 ml-5 mb-5 mr-5'
      ]),
      onDragleave: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
      onDragover: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (!_ctx.readonly ? _ctx.setFiles($event) : null), ["prevent"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["upload-area", { 'has-files': _ctx.fileList.length > 0 }])
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "file",
          ref: "input",
          multiple: "",
          onChange: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setFiles && _ctx.setFiles(...args)), ["stop"])),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetImageUploader && _ctx.resetImageUploader(...args))),
          accept: "application/pdf"
        }, null, 544), [
          [_vShow, false]
        ]),
        _createVNode(_component_TextButton, {
          class: _normalizeClass(["button", { 'button-full': _ctx.fileList.length > 0 }]),
          text: _ctx.$t('common.upload'),
          icon: require('icons/camera.svg'),
          enabled: _ctx.fileList.length < _ctx.maxFiles,
          "disabled-icon": require('icons/camera-grey.svg'),
          onOnClick: _cache[3] || (_cache[3] = ($event: any) => (!_ctx.readonly ? _ctx.onClickUpload() : null))
        }, null, 8, ["class", "text", "icon", "enabled", "disabled-icon"])
      ], 2),
      (_ctx.fileList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.fileList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (file, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["file pl-1 pr-1", { selected: _ctx.selectedIdx == idx }]),
                key: file.name,
                onClick: ($event: any) => (_ctx.selectItemAt(idx, $event))
              }, [
                _createElementVNode("img", {
                  class: "icon mr-2",
                  src: require('icons/report_orange.svg')
                }, null, 8, _hoisted_5),
                _createElementVNode("p", null, _toDisplayString(file.name), 1),
                (!_ctx.readonly)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "icon-del ml-1",
                      src: require('icons/close-red-circle.svg'),
                      onClick: ($event: any) => (_ctx.removeFile(idx))
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 34)
  ], 2))
}