import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35a48d44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "from-warpper" }
const _hoisted_2 = {
  key: 0,
  class: "input-area"
}
const _hoisted_3 = { class: "required-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePreview = _resolveComponent("FilePreview")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["file-upload-area", { 'has-file': _ctx.localFiles.length > 0 }])
    }, [
      (_ctx.localFiles.length > 0)
        ? (_openBlock(), _createBlock(_component_FilePreview, {
            key: 0,
            files: _ctx.localFiles,
            "onUpdate:files": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFiles) = $event)),
            selectedIdx: _ctx.selectedIndex,
            "onUpdate:selectedIdx": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedIndex) = $event))
          }, null, 8, ["files", "selectedIdx"]))
        : _createCommentVNode("", true),
      _createVNode(_component_FileUpload, {
        files: _ctx.localFiles,
        "onUpdate:files": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localFiles) = $event)),
        onSelectedAt: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedIndex = $event))
      }, null, 8, ["files"])
    ], 2),
    (_ctx.localFiles.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, [
            _createElementVNode("strong", _hoisted_3, _toDisplayString(_ctx.$t("common.requiredText")), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("template.templateTitle")), 1)
          ]),
          _createVNode(_component_ValidationTextField, {
            rules: "required",
            message: {
          error_required: _ctx.$t('errors.required'),
        },
            value: _ctx.localTitle,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localTitle) = $event))
          }, null, 8, ["message", "value"])
        ]))
      : _createCommentVNode("", true)
  ]))
}